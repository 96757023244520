@import "../css/_variables";

.excalidraw {
  .ShortcutsDialog-island {
    border: 1px solid var(--button-gray-2);
    margin-bottom: 16px;
  }

  .ShortcutsDialog-island-title {
    margin: 0;
    padding: 4px;
    background-color: var(--button-gray-1);
    text-align: center;
  }

  .ShorcutsDialog-shortcut {
    border-top: 1px solid var(--button-gray-2);
  }

  .ShorcutsDialog-key {
    word-break: keep-all;
    border: 1px solid var(--button-gray-2);
    padding: 2px 8px;
    margin: auto 4px;
    background-color: var(--button-gray-1);
    border-radius: 2px;
    font-size: 0.8em;
    min-height: 26px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  .ShortcutsDialog-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-top: 1px solid var(--button-gray-2);
    margin-top: 8px;
    padding-top: 16px;
  }
}
