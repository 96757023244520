@import "../css/_variables";

.Stats {
  position: fixed;
  top: 64px;
  right: 12px;
  font-size: 12px;
  z-index: 999;

  h3 {
    margin: 0 24px 8px 0;
    white-space: nowrap;
  }

  .close {
    float: right;
    height: 16px;
    width: 16px;
    cursor: pointer;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  table {
    width: 100%;
    th {
      border-bottom: 1px solid var(--input-border-color);
      padding: 4px;
    }
    tr {
      td:nth-child(2) {
        min-width: 24px;
        text-align: right;
      }
    }
  }

  :root[dir="rtl"] & {
    left: 12px;
    right: initial;

    h3 {
      margin: 0 0 8px 24px;
    }
    .close {
      float: left;
    }
  }
}
